import { documentReady } from './utils';

const CssVariables = {
    initialize: () => {

        documentReady(() => {
            CssVariables.setCssVariables();
        });

        window.onload = function() {
            CssVariables.setCssVariables();
        };

        window.onresize = function() {
            CssVariables.setCssVariables();
        };


    },
    setCssVariables: () => {
        //Scroll width
        const customScrollbarWidth = window.innerWidth - window.visualViewport.width;
        document.documentElement.style.setProperty('--scrollWidth', `${customScrollbarWidth}px`);

        // Header Height
        const pageHeaderWrapper = document.querySelector('.header__wrapper');
        const headerHeight = pageHeaderWrapper ? pageHeaderWrapper.offsetHeight : 0;
        document.documentElement.style.setProperty('--header-h', `${headerHeight}px`);

        // Footer Height
        const pageFooterWrapper = document.querySelector('.footer__wrapper');
        const footerHeight = pageFooterWrapper ? pageFooterWrapper.offsetHeight : 0;
        document.documentElement.style.setProperty('--footer-h', `${footerHeight}px`);
    }
}

CssVariables.initialize();

export default CssVariables;
