// Import pollyfills & librairies
import 'loading-attribute-polyfill/dist/loading-attribute-polyfill.js'; // lazy load
import 'what-input'; // Keybord focus

// import custom
import './cssVariables';
// import './quiz';
// import './modal';
// import './quiz-nav';
// import './accordion';
// import './forms';
// import './animations';
// import './sliders';
// import './tooltips';
// import './popins';
// import './custom-parsley';
// import './nav';
// import './bk-tabs';
// import './inputfile';
// import './btn-back-to-top';
//TODO: remove after tests new fit system
// import './fit-to-screen-border';

// Functions
